import { useEffect, useState } from "react"
import { Card, Col, FormCheck, FormControl, Row } from "react-bootstrap"
import manageApi from "../../../app/services/secured/manage"
import { ADM_GATEWAY, GatewayAPI, GatewayFormsProps } from "../../../types"
import { BlockError, FormInputGroup } from "../../misc/Blocks"
import { BtnIconLoad } from "../../misc/Buttons"
import SimpleForm from "../../misc/SimpleForm"
import { PendingLeadsButton } from "../AccountExports"

const ADMValue:React.FC<GatewayFormsProps> = gateway => {
    const [isNew, setIsNew] = useState(true);
    const config = gateway.config as ADM_GATEWAY;

    useEffect(() => {
        if (gateway.id) setIsNew(false);
    }, [gateway])

    const [gatewayAPI, callGatewayAPI] = manageApi.useHandleFormGatewayMutation();

    return <>
        <SimpleForm onSubmit={(json) => {
            gatewayAPI(isNew
                ? { company: gateway.company, form: gateway.form, gateway: '', data: json as ADM_GATEWAY } as GatewayAPI
                : { company: gateway.company, form: gateway.form, gateway: gateway.id, data: json as ADM_GATEWAY } as GatewayAPI
            )
        }}>
            <FormControl type='hidden' name='gateway_code' value='adm' />
            <Card className='mt-3'>
                <Card.Header>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex align-items-center'>
                            <FormCheck id='activate_api' type='switch' label='Activer export' name='enabled' defaultChecked={gateway?.enabled} />
                            {!isNew && gateway.id && <PendingLeadsButton company_id={gateway.company} form_id={gateway.form} gateway_id={gateway.id} />}
                        </div>
                        <h6 className='text-end m-0'>ADM Value</h6>
                    </div>
                </Card.Header>
                <Card.Body>
                    <BlockError data={callGatewayAPI} />
                    <FormInputGroup label='URL Webservice'>
                        <FormControl size='sm' type='text' name='config[webservice_url]' required
                            defaultValue={config?.webservice_url} placeholder='url' />
                    </FormInputGroup>

                    <Row>
                        <FormInputGroup as={Col} label='Valeur INFOSIG1'>
                            <FormControl size='sm' type='text' name='config[infosig1]' defaultValue={config?.infosig1} placeholder='infossig1'  />
                        </FormInputGroup>
                        <FormInputGroup as={Col} label='Valeur INFOSIG2'>
                            <FormControl size='sm' type='text' name='config[infosig2]' defaultValue={config?.infosig2} placeholder='infossig2' />
                        </FormInputGroup>
                        <FormInputGroup as={Col} label='Valeur INFOSIG3'>
                            <FormControl size='sm' type='text' name='config[infosig3]' defaultValue={config?.infosig3} placeholder='infossig3' />
                        </FormInputGroup>
                        <FormInputGroup as={Col} label='Valeur MEDIA'>
                            <FormControl size='sm' type='text' name='config[media]' defaultValue={config?.media} placeholder='media' />
                        </FormInputGroup>
                    </Row>
                    <Row>
                        <FormInputGroup as={Col} label='Valeur ORIGINE'>
                            <FormControl size='sm' type='text' name='config[origine]' defaultValue={config?.origine} placeholder='origine' />
                        </FormInputGroup>
                        <FormInputGroup as={Col} label='Valeur ASSO'>
                            <FormControl size='sm' type='text' name='config[asso]' defaultValue={config?.asso} placeholder='asso' />
                        </FormInputGroup>
                        <FormInputGroup as={Col} label='Valeur CID'>
                            <FormControl size='sm' type='text' name='config[cid]' defaultValue={config?.cid} placeholder='cid' />
                        </FormInputGroup>
                    </Row>

                    <div className='mt-3 text-end'>
                        <BtnIconLoad data={callGatewayAPI}>Enregister</BtnIconLoad>
                    </div>
                </Card.Body>
            </Card>
        </SimpleForm>
    </>
}

export default ADMValue;